import React, { useState, useEffect, Suspense } from "react";
import { TenantApi, TitleControl } from "@unity/components";
import { Button, CircularProgress, Container, LinearProgress, Typography, Fade } from "@mui/material";
import { Cancel, Error } from "@mui/icons-material";
import { fullScreenCenterContainer } from "../../styles/general";

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

const AttendForm = React.lazy(() => import("./settingsForms/attend"));

const ATTENDCATEGORY = "attend";
export default function ModuleSettingsEdit({ context, route }) {
  const [settings, setSettings] = useState(false);
  const [loading, setLoading] = useState(true);

  const settingsId = route.match.params.id;
  const tc = new TitleControl();

  const handleChange = async (name, value) => {
    const temp = {...settings};
    temp[name] = value;
    setSettings(temp);
  }

  const saveSettings = async () => {
    try {
      const res = await TenantApi.moduleSettingsUpdate(settingsId, settings);
      if(!res.ok || !res.data) throw "Call Failed";

      alert("Update Successful");
      return window.location.pathname = "/settings/module-settings";
    } catch (e) {
      console.error("Failed to update settings", e);
      alert("Failed to update settings. Please refresh and try again");
    }
  }

  const fetchSettings = async () => {
    try {
      const res = await TenantApi.moduleSettingsShow(settingsId);
      if(!res.ok || !res.data) throw "Call Failed";

      setSettings(res.data);
      return res.data;
    } catch (e) {
      console.error("Failed to fetch module settings", e);
      alert("Failed to fetch Module Settings. Please refresh and try again");
      return null;
    }
  }

  const loadData = async () => {
    setLoading(true);
    tc.setControlTitle((
      <Button
        startIcon={<Cancel/>}
        onClick={() => {window.location.pathname = "/settings/module-settings"}}
        variant="contained"
        style={{ backgroundColor: "red", color: "white" }}
      >Cancel</Button>
    ), "Module Settings Edit");
    await fetchSettings();
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, []);

  const getForm = () => {
    if(!settings || !settings.category_type) return setSettings(false);
    switch(settings.category_type) {
      case ATTENDCATEGORY: return (
        <Suspense fallback={<Fade in={true}><LinearProgress/></Fade>}>
          <AttendForm settings={settings} setSettings={handleChange} handleSave={saveSettings} context={context} />
        </Suspense>
      )
      default: return (
        <Typography variant="body1">Unknown Module Type. Please contact an admin</Typography>
      )
    }
  }

  if(loading) return (
    <div style={fullScreenCenterContainer()}>
      <CircularProgress/>
      <Typography variant="body1">Loading Settings...</Typography>
    </div>
  )

  if(!settings) return (
    <div style={fullScreenCenterContainer()}>
      <Error/>
      <Typography variant="body1">Settings Not Found</Typography>
      <Button
        variant="contained"
        onClick={() => {window.location.pathname = "/settings/module-settings"}}
        style={{ backgroundColor: "red", color: "white" }}
        startIcon={<KeyboardReturnIcon/>}
      >Return</Button>
    </div>
  )

  return (
    <Container>
      {getForm()}
    </Container>
  )
}
