const fullScreenCenterContainer = () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "90vh",
  width: "95%",
  padding: "2vw",
});

const formSubSection = () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  height: "100%",
  width: "100%",
  padding: "1.5vw",
  gap: "1vh",

  backgroundColor: "rgba(20,20,20,0.5)",
  color: "white",
  borderRadius: "5px",
  margin: "2vh",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;"
});

const formButtonControlsContainer = () => ({
  display: "flex",
  justifyContent: "flex-start",
  align: "center",
  width: "100%",
  height: "100%",
  gap: "1vw",
});

const moduleSettingsBox = () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%"
})

export { 
  fullScreenCenterContainer,
  formSubSection,
  formButtonControlsContainer,
  moduleSettingsBox
};
