import React, { useContext } from "react";
import Loader from "react-loader-spinner";

export default function Loading({ color = false }) {
  return (
    <div style={{ textAlign: "center", height: "100vh", marginTop: "12%" }}>
      <Loader
        type="TailSpin"
        // color={color ? color : context.theme.sidebar.background}
        height={80}
        width={80}
      />
    </div>
  );
}
