import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import PageHeader from "../../common/PageHeader";
import { ActivityApi, AppHeaderComponent } from "@unity/components";
import ApiLoader from "../../common/ApiLoader";
import AppButton from "../../common/AppButton";
import AuthContext from "../../services/AuthContext";
import { TextField } from "@material-ui/core";
import Loading from "../../common/Loading";

export default function CallResultTypes({ context, route }) {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState({ status: false, data: false });
  const [addNew, setAddNew] = useState(false);
  const [form, setForm] = useState({});
  //const context = useContext(AuthContext);

  const getData = async () => {
    const res = await ActivityApi.getCallActions();
    setData(res.data);
  };

  const addNewItem = () => {
    setAddNew(true);
  };

  const handleSave = async () => {
    setLoading({
      status: !loading.status,
      data: "Deleting Please Wait",
    });

    await ActivityApi.saveCallAction(form);

    setAddNew(false);
    setForm({});

    setLoading({
      status: false,
      data: "Success",
    });
  };

  const deleteItem = async (id) => {
    setLoading({
      status: !loading.status,
      data: "Deleting Please Wait",
    });
    await ActivityApi.deleteCallAction(id);
    setLoading({
      status: false,
      data: "Success",
    });
  };

  useEffect(() => {
    getData();
  }, [loading]);

  if (data) {
    return (
      <>
        <ApiLoader status={loading.status} data={loading.data} />

        <AppHeaderComponent
          name="settings"
          theme={context.theme}
          context={context}
        />
        <div className="block">
          <ul className="settings-menu">
            {data.map((item, key) => {
              return (
                <li>
                  <SettingsIcon size={30} />
                  {item.name}
                  {key > 4 && (
                    <span
                      className="next-arrow"
                      onClick={() => deleteItem(item.id)}
                    >
                      <DeleteIcon size={30} />
                    </span>
                  )}
                </li>
              );
            })}
            {addNew && (
              <li>
                <SettingsIcon size={30} />
                <TextField
                  name="name"
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
                <span className="next-arrow" onClick={() => handleSave()}>
                  <SaveIcon size={30} />
                </span>
              </li>
            )}
          </ul>
        </div>
        <AppButton
          onClick={() => addNewItem()}
          btnIcon="add"
          text="add new"
          style={{
            marginTop: 10,
            background: context.theme.sidebar.background,
            color: "white",
          }}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
