import React, { useEffect, useState } from "react";
import {
  IoIosPeople,
  IoIosArrowRoundForward,
  IoIosCheckmark,
} from "react-icons/io";
import { Link } from "react-router-dom";
import AppButton from "../../common/AppButton";
import TextField from "@material-ui/core/TextField";
import Loading from "../../common/Loading";
import { AppHeaderComponent, PermissionsApi } from "@unity/components"
export default function PermissionsGroups({ route, context, history }) {
  const [addNew, setAddNew] = useState(false);
  const [groups, setGroups] = useState(false);

  const handleSubmit = async () => {

    const res = await PermissionsApi.saveRoles(addNew);
    setAddNew(false);
  };

  const handleChange = (e) => {
    setAddNew({ ...addNew, [e.target.name]: e.target.value });
  };

  const roles = async () => {
    const res = await PermissionsApi.getRoles();
    setGroups(res.data);
  };

  useEffect(() => {
    roles();
  }, []);

  if (groups) {
    return (
      <div>
        <AppHeaderComponent name="settings" theme={context.theme}
          context={context} />
        <div style={{ paddingTop: 140 }}>
          <div className="page-section" >
            <ul className="settings-menu">
              {groups.map((item) => {
                return (
                  <li>
                    <Link
                      to={{
                        pathname: `/settings/groups/permissions/${item.id}`,
                      }}
                    >
                      <IoIosPeople size={30} />
                      {item.name}
                      <span className="next-arrow">
                        <IoIosArrowRoundForward size={30} />
                      </span>
                    </Link>
                  </li>
                );
              })}
              {addNew ? (
                <li>
                  <div>
                    <IoIosPeople size={30} />
                    <TextField
                      style={{ width: 300, color: "white!important" }}
                      name="name"
                      onChange={(e) => handleChange(e)}
                    ></TextField>
                    <TextField
                      style={{ width: 300, color: "white!important" }}
                      name="description"
                      onChange={(e) => handleChange(e)}
                    ></TextField>
                    <span className="next-arrow">
                      <IoIosCheckmark size={30} onClick={() => handleSubmit()} />
                    </span>
                  </div>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
          <Link to={"/settings/groups/create"}>
            <AppButton text="add new" btnIcon="add" stylename="primary" />
          </Link>
        </div></div>
    );
  } else {
    return <Loading />;
  }
}
