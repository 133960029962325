import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import ApiLoader from "../../common/ApiLoader";
import { AppHeaderComponent, PermissionsApi } from "@unity/components"

export default function PermissionsCreate({ context, route }) {
  const [group, setGroup] = useState({});
  const [loading, setLoading] = useState({ status: false });

  let history = useHistory()

  const handleChange = (e) => {
    const newData = { ...group };
    newData[e.target.name] = e.target.value;
    setGroup(newData);
  };

  const handleSave = async () => {
    console.log("group",group);

    setLoading({
      status: true,
      data: "Validating Data, Please Wait....",
    });

    const res = await PermissionsApi.saveRoles(group);

    setLoading({ status: true, data: 'Permissions Saved, Please Wait....' });

    setTimeout(() => {
      history.push("/settings/groups");
    }, 2000);

  };

  useEffect(() => { }, []);

  
  return (
    <div>
      <ApiLoader status={loading.status} data={loading.data} />
      <AppHeaderComponent name="settings" subpage="groups" subpage2="create" context={context} theme={context.theme} saveBtn={handleSave} />
      <div style={{ paddingTop: 120 }}>
        <div className="page-section padding-lg">
          <TextField
            label="Group Name"
            value={group.name}
            onChange={(e) => handleChange(e)}
            fullWidth={true}
            name="name"
            helperText={"Permission group name"}
          ></TextField>
          <TextField
            label="Group Description"
            value={group.description}
            onChange={(e) => handleChange(e)}
            fullWidth={true}
            name="description"
            helperText={"Permission group name"}
          ></TextField>
        </div>
      </div>
    </div>
  );
}
