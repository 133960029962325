import React, { useState, useEffect } from "react";
import { TenantApi, TitleControl } from "@unity/components";
import { Typography, Button, Container, Grid, CircularProgress } from "@mui/material";
import { fullScreenCenterContainer, moduleSettingsBox } from "../../styles/general";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Cancel } from "@mui/icons-material";

const DEFAULTPAGE = 1;
const DEFAULTPERPAGE = 500;
export default function ModuleSettingsCreate({ context }) {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);

    const tc = new TitleControl();

    const handleRegister = async (categoryName) => {
        try {
            const res = await TenantApi.moduleSettingsStore({
                category_type: categoryName,
                settings: {}
            });
            if(!res.ok || !res.data) throw "Call Failed";

            return window.location.pathname = `/settings/module-settings/${res.data.id}/edit`;
        } catch (e) {
            console.error("Failed to register module", e);
            alert("Failed to register module. Please refresh and try again");
        }
    }

    const fetchSettings = async () => {
        try {
            const res = await TenantApi.moduleSettingsIndex(
                DEFAULTPAGE,
                DEFAULTPERPAGE
            );
            if (!res.ok || !res.data) throw "Call Failed";

            setSettings(res.data.data);
            return res.data.data;
        } catch (e) {
            console.error("Failed to Load Settings", e);
            alert("Failed to laod settings. Please refresh and try again");
            return null;
        }
    };

    const loadData = async () => {
        setLoading(true);
        tc.setControlTitle(
            <Button
                startIcon={<Cancel/>}
                variant="contained"
                style={{ backgroundColor: "red", color: "white" }}
                onClick={() => {
                    window.location.pathname = "/settings/module-settings";
                }}
            >
                Back
            </Button>,
            "Module Settings Register"
        );
        await fetchSettings();
        setLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    const findSettings = (setting) => {
        const found = settings.filter((s) => s.category_type == setting);
        return Array.isArray(found) && found.length > 0
    }

    if (loading) return (
        <div style={fullScreenCenterContainer()}>
            <CircularProgress />
            <Typography variant="body1">Loading Settings...</Typography>
        </div>
    );

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div style={moduleSettingsBox()}>
                        <Typography variant="h4" gutterBottom>
                            Attend and Timekeeper
                        </Typography>
                        {
                            findSettings("attend") ? (
                                <Button
                                    variant="contained"
                                    disabled
                                    startIcon={<QueryBuilderIcon/>}
                                    fullWidth
                                >Already Registered</Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    startIcon={<QueryBuilderIcon/>}
                                    fullWidth
                                    onClick={() => {handleRegister("attend")}}
                                >Register</Button>
                            )
                        }
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}
