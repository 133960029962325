import React, { useEffect, useState } from "react";
import { TitleControl, TenantApi } from "@unity/components";
import {
  Button,
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import { fullScreenCenterContainer } from "../../styles/general";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Edit, Error } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

const DEFAULTPAGE = 1;
const DEFAULTPERPAGE = 500;
export default function ModuleSettingsIndex({ context }) {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const tc = new TitleControl();

  const fetchSettings = async () => {
    try {
      const res = await TenantApi.moduleSettingsIndex(
        DEFAULTPAGE,
        DEFAULTPERPAGE
      );
      if (!res.ok || !res.data) throw "Call Failed";

      setSettings(res.data.data);
      return res.data.data;
    } catch (e) {
      console.error("Failed to Load Settings", e);
      alert("Failed to laod settings. Please refresh and try again");
      return null;
    }
  };

  const loadData = async () => {
    setLoading(true);
    tc.setControlTitle(
      <Button
        startIcon={<AddBoxIcon />}
        variant="contained"
        style={{ backgroundColor: "green", color: "white" }}
        onClick={() => {
          window.location.pathname = "/settings/module-settings/create";
        }}
      >
        Register New Settings
      </Button>,
      "Module Settings List"
    );
    await fetchSettings();
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading)
    return (
      <div style={fullScreenCenterContainer()}>
        <CircularProgress />
        <Typography variant="body1">Loading Settings...</Typography>
      </div>
    );

  if (!Array.isArray(settings) || settings.length <= 0)
    return (
      <div style={fullScreenCenterContainer()}>
        <Error />
        <Typography variant="body1">No Settings Found</Typography>
      </div>
    );

  const cols = [
    {
      field: "category_type",
      headerName: "Module Category",
      flex: 0.5,
      align: "center",
      renderCell: (param) => (
        <Typography variant="body1" style={{ textTransform: "capitalize" }}>
          {param.row.category_type}
        </Typography>
      ),
    },
    {
      field: "_actions",
      headerName: "Actions",
      flex: 0.5,
      align: "center",
      renderCell: (param) => (
        <IconButton
          onClick={() => {
            window.location.pathname = `/settings/module-settings/${param.row.id}/edit`;
          }}
        >
          <Edit />
        </IconButton>
      ),
    },
  ];

  return (
    <Container style={{ marginTop: "5vh", height: "80vh" }}>
      <DataGrid
        columns={cols}
        rows={settings}
        getRowId={(row) => row.id}
        density="compact"
        sx={{
          boxShadow: 2,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: context.theme.sidebar.background,
            color: context.theme.sidebar.font,
            borderRadius: "0px",
          },
        }}
      />
    </Container>
  );
}
